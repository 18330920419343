import React, {useEffect, useState} from "react";
import {PageProps} from "gatsby";
import ContactModal from "@sections/ContactModal";
import PageWrapper from "@components/PageWrapper";
import SEO from "@components/SEO";
import SymplerChat from '../sympler-chat'

function ContactPage({location}: PageProps) {

  const [endpoint, setEndpoint] = useState<string | null>(null);
  const [formName, setFormName] = useState<string | null>(null);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let endpoint = params.endpoint;
    let formName = params.formName;
    setEndpoint(endpoint);
    setFormName(formName);
  }, []);




  return (
    <PageWrapper>
      <SEO title="Chat" pathname={location.pathname} />
      {endpoint && formName && (
        <SymplerChat endpoint={endpoint} formName={formName} />
      )}
    </PageWrapper>
  );
}

export default ContactPage;
